@import "~@/assets/base/styles.less";

.head-component {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  .left-div {
    width: 38%;
    background-color: black;
    @media screen and (max-width: @APP-SCREEN) {
      width: 0%;
    }
  }
  .right-div {
    width: 62%;
    background-color: white;
    padding: 24px 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: @APP-SCREEN) {
      width: 100%;
      background-color: black;
      padding: 14px 16px;
    }
  }
  .back-button {
    display: none;
    flex-direction: row;
    align-items: center;
    flex: 1;
    @media screen and (max-width: @APP-SCREEN) {
      display: flex;
    }

    img {
      width: 16px;
    }
    h2 {
      font-size: 14px;
      color: white;
      margin: 0 8px;
    }
  }
  .lang-button {
    padding: 8px 16px;
    border-radius: 36px;
    border: 1px solid #B3B3B3;
    font-size: 16px;
    color: black;
    cursor: pointer;
    @media screen and (max-width: @APP-SCREEN) {
      font-size: 14px;
      border-color: #4D4D4D;
      color: white;
    }
  }
  .connect-button {
    padding: 0 16px;
    background-color: black;
    font-size: 16px;
    color: white;
    height: 36px;
    border-style: none;
    border-radius: 36px;
    margin-left: 12px;
    @media screen and (max-width: @APP-SCREEN) {
      background-color: white;
      color: black;
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .wallet-button {
    margin-left: 12px;
    height: 36px;
    border-radius: 36px;
    border: 1px solid #B3B3B3;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: @APP-SCREEN) {
      margin-left: 8px;
      border-color: #4D4D4D;
    }

    .wallet-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 6px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        @media screen and (max-width: @APP-SCREEN) {
          width: 20px;
          height: 20px;
          border-radius: 20px;
        }
      }
      h1 {
        font-size: 16px;
        color: black;
        margin-left: 4px;
        @media screen and (max-width: @APP-SCREEN) {
          display: none;
        }
      }
    }
    .line {
      width: 1px;
      height: 20px;
      background-color: rgba(0, 0, 0, 0.20);
      margin: 0 6px;
      @media screen and (max-width: @APP-SCREEN) {
        display: none;
      }
    }
    .arrow {
      display: block;
      width: 16px;
      height: 16px;
      margin-left: 2px;
      margin-right: 10px;
      @media screen and (max-width: @APP-SCREEN) {
        display: none;
      }
    }
    .arrow-white {
      display: none;
      width: 16px;
      height: 16px;
      margin-left: 2px;
      margin-right: 10px;
      @media screen and (max-width: @APP-SCREEN) {
        display: block;
      }
    }
  }
}
.wallet-modal {
  .ant-modal {
    width: 568px !important;
  }
  .ant-modal-content {
    background-color: white;
    border-radius: 24px;
    width: 100%;

    .ant-modal-body {
      width: 100%;
      padding: 20px 24px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .tool {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        h1 {
          font-size: 24px;
          color: black;
          @media screen and (max-width: @APP-SCREEN) {
            font-size: 22px;
          }
        }
        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .list {
        width: 100%;
        margin-top: 16px;
        display: flex;
        flex-direction: column;

        .item {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          padding: 12px;
          box-sizing: border-box;
          border-radius: 12px;
          margin: 2px 0;

          img {
            width: 36px;
            height: 36px;
          }
          h1 {
            font-size: 20px;
            color: black;
            margin: 0 12px;
            flex: 1;
            @media screen and (max-width: @APP-SCREEN) {
              font-size: 16px;
            }
          }
          h2 {
            font-size: 14px;
            color: #666666;
            @media screen and (max-width: @APP-SCREEN) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.walletconnect-modal__mobile__toggle a {
  color: black;
}