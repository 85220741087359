@font-face{
  font-family: "-text-regular";
  src: url("./assets/font/regular.woff");
}
@font-face{
  font-family: "-text-bold";
  src: url("./assets/font/bold.woff");
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
body, p, div, li {
  line-height: 1.25;
}
body {
  background-color: white;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset, img {
  border: 0;
}
ol, ul, li {
  list-style: none;
}
q:before, q:after {
  content: "";
}
abbr, acronym { 
  border: 0; 
}
a:-webkit-any-link {
  text-decoration: none;
}
div, button, h1 {
  font-family: -text-bold;
}
.ant-progress-circle .ant-progress-text, h2 {
  font-family: -text-regular;
}
.ant-btn:hover, .ant-btn:focus {
  border-color: black;
}