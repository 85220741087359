@import "~@/assets/base/styles.less";

.order-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  @media screen and (max-width: @APP-SCREEN) {
    flex-direction: column;
  }

  .leftDiv {
    display: flex;
    flex-direction: column;
    width: 38%;
    min-height: 100vh;
    background-color: black;
    padding: 0 60px;
    box-sizing: border-box;
    @media screen and (max-width: @APP-SCREEN) {
      width: 100%;
      min-height: 0;
      padding: 0 16px;
      padding-bottom: 32px;
    }
  
    .backDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 100px;
      cursor: pointer;
      @media screen and (max-width: @APP-SCREEN) {
        display: none;
      }
  
      img {
        width: 16px;
      }
      h2 {
        margin: 0;
        margin-left: 12px;
        color: white;
        font-size: 16px;
      }
    }
    .desc {
      font-size: 16px;
      color: rgba(white, 0.6);
      line-height: 1.5;
      margin-top: 40px;
      @media screen and (max-width: @APP-SCREEN) {
        margin-top: 88px;
        font-size: 14px;
      }
    }
    .amount {
      margin-top: 8px;
      color: white;
      font-size: 40px;
      margin-top: 8px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 32px;
        margin-bottom: 8px;
      }
    }
    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      @media screen and (max-width: @APP-SCREEN) {
        margin-top: 16px;
      }

      .key {
        font-size: 16px;
        color: rgba(white, 0.6);
        @media screen and (max-width: @APP-SCREEN) {
          margin-top: 14px;
        }
      }
      .value {
        font-size: 16px;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: @APP-SCREEN) {
          margin-top: 14px;
        }

        img {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 6px;
          @media screen and (max-width: @APP-SCREEN) {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .flex {
      flex: 1;
    }
    .logoDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 40px;
      @media screen and (max-width: @APP-SCREEN) {
        display: none;
      }
  
      h2 {
        margin: 0;
        margin-right: 8px;
        color: rgba(white, 0.5);
        font-size: 14px;
      }
      img {
        height: 18px;
      }
    }
  }
  .rightDiv {
    width: 62%;
    min-height: 100vh;
    background-color: white;
    padding: 0 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: @APP-SCREEN) {
      width: 100%;
      padding: 0 16px;
    }

    .title {
      font-size: 24px;
      color: black;
      margin-top: 96px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 22px;
        margin-top: 24px;
      }
    }
    .load-spin {
      font-size: 30px;
      color: black;
      margin-top: 20px;
    }
    .sub-title {
      font-size: 16px;
      color: #666666;
      margin-top: 32px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 14px;
        margin-top: 24px;
      }
    }
    .amount-div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 8px;

      h1 {
        flex: 1;
        font-size: 40px;
        color: black;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 32px;
        }
      }
      h2 {
        font-size: 16px;
        color: #666666;
        @media screen and (max-width: @APP-SCREEN) {
          display: none;
        }
      }
      .spin {
        font-size: 16px;
        color: #666666;
        @media screen and (max-width: @APP-SCREEN) {
          display: none;
        }
      } 
      .countdown-div {
        width: 36px;
        height: 36px;
        margin-left: 12px;

        .ant-progress-inner {
          width: 36px !important;
          height: 36px !important;
        }
        .ant-progress-circle .ant-progress-text {
          font-size: 12px;
          color: #666666;
        }
        .ant-progress-circle-trail {
          stroke: rgba(black, 0.1);
        }
      }
    }
    .swap-txt {
      font-size: 14px;
      color: #666666;
      margin: 8px 0;
      display: none;
      @media screen and (max-width: @APP-SCREEN) {
        display: block;
      }
    }
    .swap-spin {
      font-size: 14px;
      color: #666666;
      display: none;
      @media screen and (max-width: @APP-SCREEN) {
        display: block;
      }
    }
    .select-container {
      width: 100%;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: @APP-SCREEN) {
        margin-top: 16px;
      }

      .type {
        font-size: 16px;
        color: black;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 14px;
        }
      }
      .select-div {
        width: 100%;
        height: 56px;
        margin-top: 12px;
        border: 1px solid #B3B3B3;
        background-color: white;
        border-radius: 12px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: @APP-SCREEN) {
          margin-top: 8px;
        }

        .icon {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          margin-left: 16px;
        }
        .name {
          flex: 1;
          margin-left: 8px;
          font-size: 20px;
          color: black;
          @media screen and (max-width: @APP-SCREEN) {
            font-size: 16px;
          }
        }
        .tag {
          width: 16px;
          height: 16px;
          margin: 0 20px;
        }
      }
      .disable {
        border-color: #E6E6E6;
        background-color: #FAFAFA;
        cursor: not-allowed;
      }
    }
    .unlock-div {
      width: 100%;
      margin-top: 32px;
      margin-bottom: -16px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: @APP-SCREEN) {
        margin-bottom: 0;
      }

      .tool {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .unlock-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          img {
            width: 24px;
            height: 24px;
          }
          h1 {
            font-size: 16px;
            color: black;
            text-decoration-line: underline;
            margin-left: 4px;
            @media screen and (max-width: @APP-SCREEN) {
              font-size: 14px;
              margin-left: 7px;
            }
          }
        }
        .lock-spin {
          font-size: 24px;
          color: black;
        }
        h2 {
          flex: 1;
          text-align: right;
          font-size: 14px;
          color: #666666;
          @media screen and (max-width: @APP-SCREEN) {
            font-size: 12px;
          }
        }
      }
      .countdown-div {
        width: 100%;
        margin-top: 12px;
      }
    }
    .action-button {
      width: 100%;
      height: 56px;
      background-color: black;
      font-size: 20px;
      color: white;
      margin-top: 48px;
      border-radius: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: @APP-SCREEN) {
        margin-top: 32px;
        font-size: 16px;
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        @media screen and (max-width: @APP-SCREEN) {
          width: 20px;
          height: 20px;
        }
      }
    }
    .action-button[disabled] {
      background-color: #DDD;
    }
    .disable-text {
      font-size: 14px;
      color: #CC3314;
      text-align: center;
      margin-top: 12px;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 12px;
      }
    }
    .or-div {
      width: 100%;
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .line {
        flex: 1;
        height: 1px;
        background-color: #E6E6E6;
      }
      h2 {
        font-size: 14px;
        color: #666666;
        margin: 0 16px;
      }
    }
    .statistics {
      width: 100%;
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: @APP-SCREEN) {
        flex-direction: column;
      }

      h1, h2 {
        font-size: 16px;
        color: black;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 13px;
        }
      }
      h1 {
        margin: 0 5px;
        @media screen and (max-width: @APP-SCREEN) {
          margin: 5px 3px;
        }
      }
    }
    .copy-div {
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 12px;
      border: 1px solid #E6E6E6;
      border-radius: 12px;
      @media screen and (max-width: @APP-SCREEN) {
        padding: 16px 0;
      }

      h2 {
        flex: 1;
        font-size: 20px;
        color: black;
        margin-left: 16px;
        word-break: break-all;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 16px;
        }
      }
      .copy-button {
        margin: 0 10px;
        font-size: 16px;
        color: black;
        height: 36px;
        border-radius: 36px;
        padding: 0 16px;
        @media screen and (max-width: @APP-SCREEN) {
          margin-right: 16px;
          font-size: 14px;
        }
      }
    }
    .qrcode-div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      border: 1px solid #E6E6E6;
      border-radius: 12px;
      padding: 12px 0;
    }
    .logoDiv {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 56px 0;
      display: none;
      @media screen and (max-width: @APP-SCREEN) {
        display: flex;
      }

      h2 {
        margin: 0;
        margin-right: 8px;
        color: rgba(black, 0.5);
        font-size: 14px;
      }
      img {
        height: 18px;
      }
    }
  }
}

.payment-modal-class .ant-modal {
  width: 568px !important;

  .ant-modal-content {
    border-radius: 24px;
  }
  .ant-modal-body {
    padding: 20px 12px;
  }
  .toolContainer {
    width: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 24px;
      color: black;
      @media screen and (max-width: @APP-SCREEN) {
        font-size: 22px;
      }
    }
    .close-button {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .select-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    
    .select-item {
      width: 100%;
      height: 60px;
      padding: 0 12px;
      margin: 2px 0;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .select-icon {
        width: 36px;
        border-radius: 36px;
        margin-right: 12px;
      }
      .select-token {
        font-size: 20px;
        color: black;
        flex: 1;
        @media screen and (max-width: @APP-SCREEN) {
          font-size: 16px;
        }
      }
      .select-tag {
        width: 24px;
      }
    }
  }
  .success-title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: black;
    @media screen and (max-width: @APP-SCREEN) {
      font-size: 22px;
    }
  }
  .success-content {
    padding: 30px;
    padding-bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #666666;
    box-sizing: border-box;
    @media screen and (max-width: @APP-SCREEN) {
      padding: 20px;
      font-size: 16px;
    }
  }
  .ok-button {
    width: 60%;
    height: 56px;
    background-color: black;
    font-size: 20px;
    color: white;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: @APP-SCREEN) {
      margin-top: 20px;
      font-size: 16px;
    }
  }
}